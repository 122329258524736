/* eslint no-console:0 */

import "@hotwired/turbo-rails"
import "./channels"
import "./controllers"
import "./src/**/*"
import "chartkick/chart.js"

require("@rails/activestorage").start()
require("local-time").start()

