// app/javascript/controllers/search_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"]

    connect() {
        this.previousValue = this.inputTarget.value
    }

    update() {
        const currentValue = this.inputTarget.value

        if (currentValue !== this.previousValue) {
            this.previousValue = currentValue
        }
    }
}